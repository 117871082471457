<template  >
  <div>
   
    <b-row class="p-3">
      <b-col md="3" class="">
        <b-list-group>
          <b-list-group-item  to="/info"  > <b-icon icon="person-square"   variant="success"></b-icon> {{ $t('HomePage.info')  }} </b-list-group-item>
          <b-list-group-item to="/law"  > <b-icon icon="play-btn-fill"   variant="success"></b-icon> {{ $t('HomePage.law')  }} </b-list-group-item>
          <b-list-group-item  to="/document"  > <b-icon icon="journal-medical"   variant="success"></b-icon> {{ $t('HomePage.document')  }} </b-list-group-item>
          <b-list-group-item   to="/contract">   <b-icon icon="chevron-double-right"   variant="success"></b-icon> {{ $t('HomePage.contract')  }} </b-list-group-item>
         


        </b-list-group>
        <div class="p-3">{{ $t('HomePage.service') }}</div>
        <b-list-group  >
          <b-list-group-item href="#" to="/registerqueue"   > <b-icon icon="chevron-double-right"   variant="success"></b-icon> {{ $t('HomePage.RegisterQueue') }} </b-list-group-item>
          <b-list-group-item href="#" to="/listqueue"  > <b-icon icon="chevron-double-right"   variant="success"></b-icon> {{ $t('HomePage.listqueue') }}</b-list-group-item>
          <b-list-group-item href="#" to="/qrscanqr"  > <b-icon icon="chevron-double-right"   variant="success"></b-icon> {{ $t('HomePage.qrscan') }}</b-list-group-item>
           <b-list-group-item href="#" to="/visadocument"  > <b-icon icon="chevron-double-right"   variant="success"></b-icon> {{ $t('HomePage.visadocument') }}   </b-list-group-item>  
          
        </b-list-group>
 
<div class="p-3">ເວບໄຊ</div>
        <b-list-group>
          <b-list-group-item href="http://www.mofa.gov.la/index.php/lo/" target="_blank"    > <b-icon icon="chevron-double-right"   variant="success"></b-icon>ກະຊວງການຕ່າງປະເທດ  </b-list-group-item>
          <b-list-group-item href="https://immigration.gov.la/"  target="_blank"  > <b-icon icon="chevron-double-right"   variant="success"></b-icon> ກົມຕໍາຫຼວດກວດຄົນເຂົ້າ-ອອກເມືອງ (ຕມ)  </b-list-group-item>
 
        </b-list-group>

        <div class="p-3">
           <b-link href="https://laoevisa.gov.la/index " >
            <b-img :src="require('../assets/eVisax200W.png')" fluid alt="Responsive image"></b-img>
           </b-link>

          
        </div>
      </b-col>
      <b-col>
        <div>
          <b-carousel id="carousel-no-animation" style="text-shadow: 0px 0px 2px #000" no-animation indicators
            img-width="1024" img-height="230">
            <b-carousel-slide caption=" " img-src="https://consularlao.gov.la/img/banner1.jpeg">
            </b-carousel-slide>
            <b-carousel-slide caption=" " img-src="https://consularlao.gov.la/img/banner2.jpeg">
            </b-carousel-slide>
            
          </b-carousel>
        </div>
      </b-col>
      <b-col md="3">
        <b-calendar block locale="lao" v-model="today"></b-calendar>

        <div>
          
        </div>
      </b-col>
    </b-row>

   

   
  </div>
</template>
<script>

export default {
  methods: {
  },
  data() {
    return {
      today:new Date(),
      items:[]
    }
  },
  created() {
  
  },

}
</script>
<style >
.navitem {
  font-weight: bold;

}

.bg {
  background-color: white;
}
</style>