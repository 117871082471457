<template>
  <div id="app">
    <div class="header text-left">
      <!-- <b-row>
        <b-col class="w_logo" >
          <b-img left :src="require('./assets/laos_logo.png')" v-bind="mainProps" alt="Left image"></b-img>
        </b-col>
        <b-col >
          ກົມກົງສຸນ ກະຊວງການຕ່າງປະເທດ
        </b-col>
      </b-row> -->
      <b-row>

        <b-avatar class="ml-3" size="88px" :src="require('./assets/laos_logo.png')"></b-avatar>


        <b-col class="p-3" >
          <span class="mr-auto h5"> ກົມກົງສຸນ ກະຊວງການຕ່າງປະເທດ </span>
          <br>
          <span class="mr-auto h5"> Department of Consular Affairs </span>

        </b-col>
      </b-row>



    </div>
    <b-row>
      <b-col>
        <b-navbar toggleable="lg"  variant="info" >
          <!-- <div class="text-center">
            <b-navbar-brand href="#" to="/">{{ $t('HomePage.Home') }}</b-navbar-brand>
            <b-navbar-brand href="#">{{ $t('HomePage.News') }}</b-navbar-brand>
            <b-navbar-brand href="#">{{ $t('HomePage.Dep') }}</b-navbar-brand>
            <b-navbar-brand href="#">{{ $t('HomePage.Forms') }}</b-navbar-brand>
            <b-navbar-brand href="#" to="/registerqueue">{{ $t('HomePage.RegisterQueue') }}</b-navbar-brand>
          </div> -->



          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>


          <b-collapse id="nav-collapse" is-nav>
                      
            <b-navbar-nav>
              <b-nav-item class="navitem" to="/"> {{ $t('HomePage.Home') }} </b-nav-item>
              
              <b-nav-item class="navitem"  to="/info"> {{ $t('HomePage.info')  }} </b-nav-item>
              <b-nav-item class="navitem" to="/law"> {{ $t('HomePage.law')  }} </b-nav-item>
              <b-nav-item class="navitem" to="/document"> {{ $t('HomePage.document') }} </b-nav-item>
          
              <!-- <b-nav-item-dropdown  class="navitem" :text="$t('HomePage.service')"  >
                <b-dropdown-item href="#"> {{ $t('HomePage.RegisterQueue') }} </b-dropdown-item>
                
              </b-nav-item-dropdown>   -->
              <b-nav-item class="navitem"  to="/about" > {{ $t('HomePage.aboutus')  }} </b-nav-item>
              <b-nav-item class="navitem"  to="/contract"> {{ $t('HomePage.contract')  }} </b-nav-item>

              <!-- <b-nav-item class="navitem"  to="/registerqueue"  >   {{ $t('HomePage.RegisterQueue') }}  </b-nav-item>
              <b-nav-item class="navitem"  to="/listqueue"  >   {{ $t('HomePage.listqueue') }}  </b-nav-item>
              <b-nav-item class="navitem"  to="/qrscan"  >   {{ $t('HomePage.qrscan') }}  </b-nav-item> -->

            </b-navbar-nav> 
            

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

<!-- 
              <b-nav-item-dropdown text="Lang" right>
                <b-dropdown-item href="#">EN</b-dropdown-item>
                <b-dropdown-item href="#">LA</b-dropdown-item>
              </b-nav-item-dropdown> -->


            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
    
    <b-row>
      <!-- <b-col md="3" class="m-3 border  bg">
        Left
      </b-col> -->
      <b-col class="p-3" style=" min-height: 600px;" >



        <router-view />
      </b-col>
      <!-- <b-col md="2"  class="border vh-100">
        Right
      </b-col> -->
    </b-row>
    
    <b-row    >
      <b-col>
         <b-navbar toggleable="lg" type="wite" variant="info"  >
  <div class="text-truncate text-center"  >
  Copyright © 2022, Ministry of Foreign Affairs of LAO PDR. All Rights Reserved.
23 Singha Road, Phonesay Village, Xaysettha District, Vientiane,  (Develop By LAOSOFT)
  </div>



         </b-navbar>
      </b-col>
    </b-row>

    <b-modal v-for="item in items" :key="item.id" id="modal-xl" visible size="xl" hide-footer title="ແຈ້ງການ">
      <b-img :src="item.link" fluid alt="Responsive image"></b-img>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data () {
    return {
      items:[]

    }
  },
  created () {
    axios
            .get(
                this.$store.getters.getBaseUrl + "/noties/1/1",{
                  headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
          "Accept-Encoding": "identity"
        }
                }
            )
            .then((response) => {
                // JSON responses are automatically parsed.
                this.items = response.data

            })
  },
  name: 'App',
  components: {

  }
}
</script>

<style>
#app {
  font-family: 'Noto Sans Lao Regular', 'Phetsarath OT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;


}

.bg {
  background-color: #FFFFFF;
}

.header {
  height: 88px;
  background-color: #FFFFFF;
}

.w_logo {
  width: 100px;
}
</style>
