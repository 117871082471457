import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
     Login: {
         Auth_Login :   false,
         Username   :   '',
         Token      :   '',
         Level      :   ''
     },
        base_url       :   'https://consularlao.gov.la/api',
        base_url2       :   'https://consularlao.gov.la/data',
   // base_url       :   'http://localhost:9988/api',
    //    base_url2:'http://localhost:9988/api'


}
const mutations = {
    setLogin(state,b){
        state.Login.Auth_Login=b
        
        
    },
    setUsername(state,b){
        state.Login.Username=b
    }

}
const actions = {
    setLogin({ commit }){
        commit("setLogin",true)
       
    },
    setUsername({ commit,user }){
        commit("setUsername",user)
    }

}
const getters = {
    getAuth(){
        return state.Login.Auth_Login
    },
    getBaseUrl(){
        return state.base_url
    },
    getBaseUrl2(){
        return state.base_url2
    }
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})