import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home.vue'
 
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path: 'NotFound',
        name: 'NotFound',
        component: () => import('../pages/404.vue')
      },
    ]
  },
   
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/registerqueue',
    name: 'registerqueue',
    component: () => import('../pages/registerqueue.vue')
  },
  {
    path: '/registerinfo',
    name: 'registerinfo',
    component: () => import('../pages/registerinfor.vue')
  },
  {
    path: '/pageqa1/:age',
    name: 'pageqa1',
    component: () => import('../pages/page_qa1.vue')
  },
  {
    path: '/qr/:value',
    name: 'qr',
    component: () => import('../pages/qr.vue')
  },
  {
    path: '/incorrect',
    name: 'incorrect',
    component: () => import('../pages/data_incorrect.vue')
  },
  {
    path: '/listqueue',
    name: 'listqueue',
    component: () => import('../pages/list_queue')
  },
  {
    path: '/qrscan',
    name: 'qrscan',
    component: () => import('../pages/qrscan')
  },
  {
    path: '/queueinfo',
    name: 'queueinfo',
    component: () => import('../pages/queue_info')
  },
  {
    path: '/queueinfo2',
    name: 'queueinfo2',
    component: () => import('../pages/queue_info2')
  },

  {
    path: '/info',
    name: 'info',
    component: () => import('../pages/info')
  },
  {
    path: '/law',
    name: 'law',
    component: () => import('../pages/law')
  },
  {
    path: '/document',
    name: 'document',
    component: () => import('../pages/document')
  },
  {
    path: '/contract',
    name: 'contract',
    component: () => import('../pages/contract')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../pages/about')
  },
  {
    path: '/qrscanqr',
    name: 'qrscanqr',
    component: () => import('../pages/qrscan2')
  },

  {
    path: '/visadocument',
    name: 'qrscanqr',
    component: () => import('../pages/visadocument')
  },
  {
    path: '/msg',
    name: 'msg',
    component: () => import('../pages/msg')
  },
  {
    path: '/dailyreport',
    name: 'dailyreport',
    component: () => import('../pages/daily_report')
  },
  {
    path: '/listregister',
    name: 'listregister',
    component: () => import('../pages/list_queue_day')
  },
  {
    path: '/qradmin/login',
    name: 'qrlogin',
    component: () => import('../pages/Login')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../pages/404.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
 // base: location.pathname ,
 base: "/",
  routes
})

/*
router.beforeEach((to, from, next) => {
  //  ${//to and from are Route Object,next() must be called to resolve the hook}
  //let iSlogin= store.state.Login.Auth_Login 
  let iSlogin = true
try {
    let Login = JSON.parse(localStorage.getItem('Login'))
   // console.log(Login.Auth_Login)
 
  if (Login == null) {
    iSlogin = false
  } else {
    iSlogin = Login.Auth_Login
  }
} catch (error) {
  iSlogin = false
 // console.log(error)
}


  //this.$store.setUsername(Login.username)

  //console.log(iSlogin)
  // if (to.meta.requiresAuth) {
  if (to.path != '/Home') {
    if (iSlogin) {
      // console.log('There is a token, resume. (' + to.path + ')');
      next();
    } else {
      //  console.log('There is no token, redirect to login. (' + to.path + ')');
      next('Home');
      // next('/login');
      // router.push('login');
    }
  } else {
    //  console.log('You\'re on the login page');
    if (iSlogin) {
      next('/');
    } else {
      next();
    }
  }

  next();





})

*/


export default router
