import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vScroll from 'vue-scroll'
Vue.use(vScroll)
//import { library } from '@fortawesome/fontawesome-svg-core';
//import{ fas } from '@fortawesome/free-solid-svg-icons';
//library.add(fas);


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/fontface/fontnoto.css'
//import VueSwal from 'vue-swal'

//import VueSimpleAlert from "vue-simple-alert";

import VueI18n from 'vue-i18n'
import { languages } from './i18n/index.js'
import { defaultLocale } from './i18n/index.js'

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

//Vue.use(VueSimpleAlert);
//Vue.use(VueSwal);
Vue.use(VueI18n)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
//Vue.prototype.Stimulsoft = window.Stimulsoft;
//Vue.prototype.window = window
//console.log( window.Stimulsoft)

const messages = Object.assign(languages)


var i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'la',
  messages
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
 
  render: h => h(App),
}).$mount('#app')
